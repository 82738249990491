import 'bootstrap/dist/css/bootstrap.min.css';
import Style from "./style.css";

export default function ConvertElements({mode,blockElements}){
    function funcsFromMode(){
        switch(mode){
            case 'html':
                return (<ConvertToHtml blockElements={blockElements} />);
                break;
            case 'text':
                return (<ConvertToText blockElements={blockElements} />);
                break;
            case 'markdown':
                return (<ConvertToMarkDown blockElements={blockElements} />);
                break;
        }
    }
    return (
        <div className="LiCSSsLiveEditor__blockElements">
            {funcsFromMode()}
        </div>
    );
}

function createKey(){
    return Math.random().toString(32).substring(2);
}
/**
 * html
 * @param {blockElements} 
 * @returns 
 */
function ConvertToHtml({blockElements}){
    let entities=[];//文字装飾追加
    let links=[];;//link追加
    for(let i=0;i<blockElements['blocks'].length;++i){
        let id=blockElements['blocks'][i]['id'];
        //blockごとに配列追加
        entities[id]=[];
        links[id]=[];
        //文字ごとに配列追加
        if(blockElements['blocks'][i]['text']==null){
            blockElements['blocks'][i]['text']="";
        }
        for(let m=0;m<blockElements['blocks'][i]['text'].length;++m){
            entities[id][m]={
                style:{},
                links:[]//link key
            };
        }
        //entity追加
        if(blockElements['entities'] && blockElements['entities'][id]){
            for(let n=0;n<blockElements['entities'][id].length;++n){
                if(blockElements['entities'][id][n]){
                    for(let m=blockElements['entities'][id][n]['from'];m<=blockElements['entities'][id][n]['to'];++m){
                        switch(blockElements['entities'][id][n]['type']){
                            case 'style':
                                entities[id][m]['style']=Object.assign(entities[id][m]['style'],blockElements['entities'][id][n]['style']);
                                break;
                        }
                }
                }
            }
        }
        //link追加
        if(blockElements['links'] && blockElements['links'][id]){
            links[id]=[].concat(blockElements['links'][id]);
            for(let n=0;n<blockElements['links'][id].length;++n){
                for(let m=blockElements['links'][id][n]['from'];m<=blockElements['links'][id][n]['to'];++m){
                    switch(blockElements['links'][id][n]['type']){
                        case 'link':
                            entities[id][m]['links'].push(blockElements['links'][id][n]['key'])
                            break;
                    }
                }
            }
        }
    }
    return (
        <>{blockElements['blocks'].map(block=>
            <HtmlBlock key={block['id']} block={block} entity={entities[block['id']]} link={links[block['id']]} align={block['align']} />
        )}
        </>
    );
}
function HtmlBlock({block,entity,link,align}){
    let Outer;
    let style={
        textAlign:align
    };
    let TextObject=[];
    if(block['type']!='raw'){
        Outer=block['type'];
        for(let i=0;i<block['text'].length;++i){
            if(entity[i]['links'].length){
                //linkあり
                let obj=[];
                let n=link[entity[i]['links'][0]]['from'];
                for(n;n<=link[entity[i]['links'][0]]['to'];++n){
                    obj.push({
                        type:'text',
                        text:block['text'][n],
                        style:entity[n]['style']
                    });
                }
                TextObject[i]={
                    type:'link',
                    data:link[entity[i]['links'][0]]['data'],
                    text:obj
                };
                i=n-1;
            }else{
                //linkなし
                TextObject[i]={
                    type:'text',
                    text:block['text'][i],
                    style:entity[i]['style']
                };
            }
        }
    }else{
        Outer='div';
        TextObject[0]={
            type:'raw',
            text:block['text']
        };
    }
    function ConvertTag(obj){
        switch(obj['type']){
            case 'text':
                return (<Inline key={createKey()} text={obj['text']} style={obj['style']} />);
                break;
            case 'raw':
                return (
                    <div key={createKey()} dangerouslySetInnerHTML={{__html:obj['text']}} />
                );
                break;
            case 'link':
                return(
                    <a key={createKey()} href={obj['data']['link']} target={obj['data']['target']}>{obj['text'].map(innerObj=>ConvertTag(innerObj))}</a>
                );
                break;
        }
    }
    function Inline({text,style}){
        function Export(){
            if(Object.keys(style).length){
                return (<span style={style}>
                    {text}
                </span>);
            }else{
                return (<>{text}</>);
            }
        }
        return (
            <>{Export()}</>
        );
    }
    return(
        <Outer style={style}>
            {TextObject.map(obj=>
                ConvertTag(obj)
            )}
        </Outer>
    );
}
/**
 * text
 * @param {blockElements}
 * @returns 
 */
function ConvertToText({blockElements}){
    return (
        <>{blockElements['blocks'].map(block=>
            <p key={block['id']}>
                {block['text']}
            </p>
        )}
        </>
    );
}
/**
 * markdown
 * @param {blockElements}
 * @returns 
 */
function ConvertToMarkDown({blockElements}){
    return (
        <>
            MarkDown
        </>
    );
}