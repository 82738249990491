/**
 *API通信関数
 *
 * @export
 * @param {*} porps={
 * url:URL,
 * method:POST
 * body:BODY
 * }
 * @return Promise 
 */
let url;
if(window.location.hostname=='localhost'){
    url='http://192.168.11.2/canteach/api.php';
}else{
    url='https://canteach.jp/api/';
}
export const ApiUrl=url;
export default function Fetch(props){
    const body=props;
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let rand_str = '';
    for ( var i = 0; i < 8; i++ ) {
        rand_str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if(body['header']){
        body['header']['Nonce']=rand_str;
    }else{
        body['header']={'Nonce':rand_str};
    }
    return new Promise((resoleve,reject)=>{
        fetch(url,{method:'POST',headers:{'Accept':'application/json','Content-Type':'application/json'},body:JSON.stringify(body)})
            .then(res=>res.json())
            .then(data=>{
                return resoleve(data);
            })
            .catch((error)=>{
                console.log(error);
                return reject(error);
            });
    })
}