import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NotFound from './src/NotFound';
import Page from './src/page';

export default function App() {
    return (
        <BrowserRouter basename='/'>
            <Routes>
                <Route path="/" element={<NotFound />} />
                <Route path="/:account" element={<NotFound />} />
                <Route path="/:account/:page" element={<Page />} />
            </Routes>
        </BrowserRouter>
    );
}

