import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Errors from "./errors";
import Fetch from "./fetch";
import NotFound from "./NotFound";
import ConvertElements from "../lib/LiCSSsLiveEditor/LiCSSLiveEditorConverter";
import { Container } from "react-bootstrap";

let footerElement=[];
export default function Page(){
    const {account,page}=useParams();
    const [Status,setStatus]=useState(100);
    const [Item,setItem]=useState();
    useEffect(()=>{
        let send={
            url:'v1/lp/view/'+account+'/'+page,
            method:'GET'
        };
        removeAdd();
        Fetch(send).then(data=>{
            if(data['result']){
                if(data['payloads']['status']==2){
                    setStatus(200);
                    setItem(data['payloads']);
                    document.title=data['payloads']['page']['title'];
                    document.getElementById('PageDescription').setAttribute('content',data['payloads']['page']['description']);
                    let settings=data['payloads']['settings'];
                    let head=document.head;
                    let body=document.body;
                    ['meta','link','script'].forEach(key=>{
                        createElement(settings,'headers',key,head);
                    })
                    //createElement(settings,'footers','script',body);
                    footerElement=[settings,'footers','script',body];
                }else{
                    setStatus(404);
                }
            }else{
                setStatus([data['error']['code'],data['error']['description']]);
            }
        }).catch(error=>{
            setStatus(500);
            console.log(error);
        })
    },[]);
    return (
        <>
            {Status==200?Item?
            <CreatePage template={Item['settings']['templates']['TemplateId']} header={Item['settings']['header']} body={Item['page']['html']} footer={Item['settings']['footers']} />:"":
            <Errors error={Status} />}
        </>
    );
}
function createElement(settings,type,key,target){
    for(let i=0;i<settings[type][key+'s'].length;++i){
        let element=document.createElement(key);
        for(let n=0;n<settings[type][key+'s'][i].length;++n){
            element.setAttribute(settings[type][key+'s'][i][n]['attribute'],settings[type][key+'s'][i][n]['value']);
        }
        element.setAttribute('class','js-canteach-add');
        target.appendChild(element);
    }
}
function removeAdd(){
    let add=document.getElementsByClassName('js-canteach-add');
    for(let i=0;i<add.length;++i){
        add[i].remove();
    }
}
function CreatePage({template,header,body,footer}){
    function selectTemplate(){
        switch(template){
            case 1:
                return (<PageTemplate_1 header={header} body={body} footer={footer} />);
                break;
        }
    }
    return(
        <>
        {selectTemplate()}
        </>
    );
}
function CreateLogo(logo){
    return(
        <>{logo?
        <div className="text-end text-light fst-italic">
            Powered By <a href="https://canteach.jp" className="text-light" target="_blank">CanTeach</a>.&nbsp;&nbsp;
            <a href="https://canteach.jp" target="_blank">
                <img src="/assets/img/icon192x192.png" width="75px" />
            </a>
        </div>:""}
        </>
    );
}
function PageTemplate_1({header,body,footer}){
    useEffect(()=>{
        createElement(footerElement[0],footerElement[1],footerElement[2],footerElement[3]);
        console.log(document.getElementsByClassName('canteach-serma-project__form-wrapper'));
    },[])
    return (
        <>{header['logos']['visible']?
            <header className="w-100" role="heading">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <a href="" className="navbar-brand" style={{color:"var(--basic-color)"}}>{header['logos']['text']}</a>
                    </div>
                </nav>
            </header>:""}
            <Container>
                <ConvertElements mode={body['mode']} blockElements={body} />
            </Container>
            <footer className="w-100 bg-dark" role="contentinfo">{footer['copyrights']['visible']?
                <div className="position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center text-light">
                                {footer['copyrights']['type']==='TEXT'?footer['copyrights']['text']:""}
                            </div>
                        </div>
                    </div>
                </div>:""}
                {CreateLogo(footer['logo'])}
            </footer>
        </>
    );
}