export default function Errors({error}){
    let errorCode;
    let description="";;
    if(Array.isArray(error)){
        errorCode=error[0];
        if(error[1]){
            description=error[1];
        }
    }else{
        errorCode=error;
    }
    function create(){
        switch(Number(errorCode)){
            case 100:
                return ["Loading",'通信中です'];
                break;
            case 403:
                return ["403 Forbitten",'アクセスできないページです。'];
                break;
            case 404:
                return ["404 NotFound",'お探しのページは見つかりませんでした。'];
                break;
            case 500:
                return ["500 Server Error",'サーバーエラーです。'];
                break;
            default:
                return [errorCode+" Error Occured",'エラーが発生しました。'];
                break;
        }
    }
    function convertDescription(defaultValue){
        if(description && description.length){
            return(
                <ul>{description.map(des=>
                <>{des.map((text,index)=>
                    <li key={index}>{text}</li>
                )}
                </>)}
                </ul>
            );
        }else{
            return defaultValue;
        }
    }
    let display=create();
    return (
        <div className="LoadingWrapper">
            <div className="LoadingWrapper__content">
                <h1 className="LoadingWrapper__title">
                    {display[0]}
                </h1>
                <div className="LoadingWrapper__description">
                    {convertDescription(display[1])}
                </div>
            </div>
        </div>
    );
}